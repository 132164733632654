import React, { useEffect, useMemo, useRef } from 'react';
import {
  useCardSectionView,
  useRemoveEvent,
} from '@amzn/d16g-rodeo-card-common';
import {
  AND,
  AudienceTargetingGroup,
  OR,
} from 'src/MFE/lineItem/model/AudienceTargetingGroup';
import { LineItemProposalPageState } from 'src/model/LineItemPageState';
import { MFEBootstrap } from '@mfe/state-management';
import SummaryViewContainer from 'src/MFE/lineItem/summary/SummaryViewContainer';
import useFormField from 'src/utils/hooks/useFormField';
import { ComponentIds, LineItemTypes } from 'src/utils/MFEConstants';
import AudienceEditViewContainer from 'src/MFE/lineItem/edit/AudienceEditViewContainer';
import ApiData from 'src/MFE/lineItem/model/api';
import '@amzn/unified-data-table-components/styles/css/udc-theme.css';
import {
  ConvertFromLegacySegmentStructure,
  ConvertToLegacySegmentStructure,
  CustomElementSegment,
  LegacyAudienceGroup,
} from 'src/utils/AudienceTargetingConverters';
import { getImpressionSupplyType } from 'src/utils/ImpressionSupplyType';
import {
  DisabledViewWrapper,
  FeatureRow,
  FeatureTitle,
  Hint,
} from '@amzn/d16g-rodeo-website-shared-components';
import * as IdGenerator from 'src/utils/IdGenerator';
import { Text, ThemeProvider } from '@amzn/storm-ui';
import useIsInputMethodAdvanced from 'src/utils/hooks/targeting/useIsInputMethodAdvanced';
import useAdvancedTextWidgetEditing from 'src/utils/hooks/targeting/useAdvancedTextWidgetEditing';
import PricingHelper from 'src/utils/PricingHelper';
import { isWeblabActive, Weblabs } from 'src/utils/weblabUtil';
import { Audience1PFeeView } from 'src/MFE/lineItem/model/PageStateModels';
import { calculateAudienceFee } from 'src/utils/PricingUtils';
import { TranslatorString } from 'src/Localization/LocalizationConfig';
import {
  LocalizedText,
  translateText,
} from 'src/Localization/LocalizationText';
import { useBundle } from '@amzn/react-arb-tools';
import {
  CombinedAudienceClient,
  useMaxAudiencesFees,
} from '@amzn/d16g-pricing-react-utils';

interface AudienceTargetingContainerProps {
  pageState: MFEBootstrap.PageState<LineItemProposalPageState>;
  segmentTargeting: AudienceTargetingGroup[];
  advertiserId: string;
  apiData: ApiData;
  isInternalUser: boolean;
  isEntityAllowListed: boolean;
  isAudienceIncompatible: boolean;
  isEntitySuggestionsAllowListed: boolean;
  isInclusionDisabled?: boolean;
  inclusionDisabledReasonCode?: string;
  funnelStage?: string;
  audienceTargetingMatchType?: string;
  isAudienceTargetingMatchTypeAllowListed?: string;
}

export const EMPTY_TARGETING_GROUP: AudienceTargetingGroup = {
  dealBasedTargetingEnabled: false,
  intrasetOperator: AND,
  operator: OR,
  segments: [],
};

function AudienceTargetingContainer({
  pageState,
  segmentTargeting,
  apiData,
  isInternalUser,
  isEntityAllowListed,
  isAudienceIncompatible,
  isEntitySuggestionsAllowListed,
  isInclusionDisabled,
  inclusionDisabledReasonCode,
  funnelStage,
  audienceTargetingMatchType,
  isAudienceTargetingMatchTypeAllowListed,
}: AudienceTargetingContainerProps) {
  useMemo(() => {
    CombinedAudienceClient.initialize(apiData);
  }, [apiData]);

  const [audienceSegments, setAudienceSegments] = useFormField<
    AudienceTargetingGroup[]
  >(
    (lineItemModel) => lineItemModel?.segmentTargeting?.builder,
    ComponentIds.AUDIENCE_TARGETING,
    pageState,
    segmentTargeting
  );

  const [audienceFee, setAudienceFee] = useFormField<Audience1PFeeView>(
    (lineItemModel) => lineItemModel?.dataFeeView,
    ComponentIds.AUDIENCE_TARGETING,
    pageState
  );

  const [audience3PFee, set3PAudienceFee] = useFormField<number>(
    (lineItemModel) => lineItemModel?.automotiveFeeView?.millivalue,
    ComponentIds.AUDIENCE_TARGETING,
    pageState
  );

  const [maxThirdPartyDataFeeView, setMaxThirdPartyDataFeeView] =
    useFormField<number>(
      (lineItemModel) => lineItemModel?.maxThirdPartyDataFeeView?.millivalue,
      ComponentIds.AUDIENCE_TARGETING,
      pageState,
      undefined
    );

  const [lineItemType, setLineItemType] = useFormField<string>(
    (lineItemModel) => lineItemModel?.type,
    ComponentIds.AUDIENCE_TARGETING,
    pageState
  );

  const [twitchInInventory, setTwitchInInventory] = useFormField<boolean>(
    (lineItemModel) => {
      if (lineItemType.value === LineItemTypes.VIDEO)
        return lineItemModel?.externalSupplySourceView
          ?.includeTwitchSelfServiceStandalone;
      else if (lineItemType.value === LineItemTypes.STANDARD_DISPLAY)
        return lineItemModel?.externalSupplySourceView?.amazonOOSites
          ?.twitch_web_display;
    },
    ComponentIds.AUDIENCE_TARGETING,
    pageState
  );

  const [bundle, isBundleLoading] = useBundle('strings');
  const convertedSelectedAudiences = ConvertFromLegacySegmentStructure(
    audienceSegments.value
  );

  const lineItemState =
    pageState?.getCurrentPageState()?.lineItemV1 ||
    pageState?.getCurrentPageState()?.proposalV1;

  // calling pricing hooks for use in display
  // @ts-ignore
  const selectedAudiences =
    // @ts-ignore
    (lineItemState?.segmentTargeting?.builder?.flatMap(
      (group) => group.segments
    ) as CustomElementSegment[]) ?? [];
  const { observedMaxFirstPartyFee, observedMaxThirdPartyFee } =
    useMaxAudiencesFees(selectedAudiences);

  useEffect(() => {
    if (audienceSegments.value)
      setAudienceSegmentsWithAudienceFee(
        ConvertToLegacySegmentStructure(
          convertedSelectedAudiences,
          getImpressionSupplyType(lineItemState)
        )
      );
  }, [
    !!audienceSegments.value,
    getImpressionSupplyType(lineItemState),
    observedMaxThirdPartyFee,
    observedMaxFirstPartyFee,
  ]);

  useEffect(() => {
    if (!isWeblabActive(Weblabs.ADSP_PRICING_UI_REFACTOR, 'T2')) {
      const pricingHelper = PricingHelper.getInstance();

      const updateAudiencePricing = async () => {
        try {
          const pricedSegments: LegacyAudienceGroup[] =
            await pricingHelper.requestPricingForLegacyAudiences(
              segmentTargeting as LegacyAudienceGroup[],
              apiData,
              getImpressionSupplyType(lineItemState)
            );
          setAudienceSegments([]);
          setAudienceSegmentsWithAudienceFee(
            pricedSegments as AudienceTargetingGroup[]
          );
        } catch (e) {
          console.error('Error requesting prices for legacy audiences');
        }
      };

      if (!pricingHelper.requestCount) updateAudiencePricing();
    }
  }, []);

  // will not be needed after pricing refactor
  const feeUpdateCountRef = useRef(0);

  const setAudienceSegmentsWithAudienceFee = (
    audiences: AudienceTargetingGroup[]
  ) => {
    if (isWeblabActive(Weblabs.ADSP_PRICING_UI_REFACTOR, 'T2')) {
      setAudienceSegments(audiences);
      setAudienceFee({
        millivalue: Number(observedMaxFirstPartyFee.value) ?? 0,
        supplyFees: [],
        currency: observedMaxFirstPartyFee.currency,
      });

      set3PAudienceFee(Number(observedMaxThirdPartyFee.value) ?? 0);
      setMaxThirdPartyDataFeeView(Number(observedMaxThirdPartyFee.value) ?? 0);
    } else {
      setAudienceSegments(audiences);

      const currentCallCount = ++feeUpdateCountRef.current;

      calculateAudienceFee(
        audiences as LegacyAudienceGroup[],
        lineItemState
      ).then((fees) => {
        // Check if current call is still the latest before updating state
        if (currentCallCount === feeUpdateCountRef.current) {
          setAudienceFee(fees.audience1PFees);

          if (isWeblabActive(Weblabs.ADSP_PRICING_3P_FEE_CALCULATION))
            set3PAudienceFee(fees.audience3PFees);

          setMaxThirdPartyDataFeeView(fees.audience3PFees);
        }
      });
    }
  };

  useRemoveEvent(() => {
    setAudienceSegmentsWithAudienceFee([EMPTY_TARGETING_GROUP]);
  });

  const isStringDefined = (inputStr): boolean =>
    !!(inputStr !== '' && inputStr !== null && inputStr !== undefined);

  const isAdvancedTargetingEnabled = useIsInputMethodAdvanced(pageState);
  const isAdvanced = isInternalUser
    ? isAdvancedTargetingEnabled
    : isStringDefined(
        pageState.getCurrentPageState().lineItemV1.targetingAdvancedSegments
      );

  useAdvancedTextWidgetEditing(
    isInternalUser,
    isAdvanced,
    `${translateText(
      bundle,
      TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE_ATS_ENABLED_TEXT
    )}`
  );

  const textInputId = IdGenerator.generateHtmlId(
    'summaryView',
    ComponentIds.AUDIENCE_TARGETING
  );

  const getDisabledTextBasedOnUserType = () =>
    isInternalUser ? (
      <Hint warning>
        <LocalizedText
          translatorString={
            TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE_ATS_ENABLED_TEXT
          }
        />
      </Hint>
    ) : (
      <Text>
        <LocalizedText
          translatorString={
            TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE_ATS_ENABLED_EXTERNAL_USER_TEXT
          }
        />
      </Text>
    );

  const blockedWidgetView = (
    <FeatureRow
      title={
        <FeatureTitle
          htmlFor={textInputId}
          label={translateText(
            bundle,
            TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE
          )}
        />
      }
      value={getDisabledTextBasedOnUserType()}
    />
  );

  const getBlockedViewForUser = () =>
    isInternalUser ? (
      <div style={{ margin: '100px auto auto 100px' }}>
        {getDisabledTextBasedOnUserType()}
      </div>
    ) : (
      <div>{blockedWidgetView}</div>
    );

  const editView = (
    <ThemeProvider>
      <DisabledViewWrapper
        isChildVisible={!isAdvanced}
        fallback={getBlockedViewForUser()}
      >
        <AudienceEditViewContainer
          pageState={pageState}
          apiData={apiData}
          setAudienceSegments={setAudienceSegmentsWithAudienceFee}
          audienceSegments={audienceSegments.value}
          isCard
          isEntityAllowListed={isEntityAllowListed}
          isAudienceIncompatible={isAudienceIncompatible}
          isEntitySuggestionsAllowListed={isEntitySuggestionsAllowListed}
          isInclusionDisabled={isInclusionDisabled}
          inclusionDisabledReasonCode={inclusionDisabledReasonCode}
        />
      </DisabledViewWrapper>
    </ThemeProvider>
  );

  const summaryView = (
    <ThemeProvider>
      <DisabledViewWrapper
        isChildVisible={!isAdvanced}
        fallback={blockedWidgetView}
      >
        <SummaryViewContainer
          pageState={pageState}
          audienceSegments={audienceSegments.value}
          isAudienceIncompatible={isAudienceIncompatible}
          isEntityAllowListed={isEntityAllowListed}
          funnelStage={funnelStage}
          twitchInInventory={twitchInInventory.value}
          lineItemType={lineItemType.value}
          setTwitchInInventory={setTwitchInInventory}
          audienceTargetingMatchType={audienceTargetingMatchType}
          isAudienceTargetingMatchTypeAllowListed={
            isAudienceTargetingMatchTypeAllowListed
          }
          tactic={apiData.automatedTactic}
        />
      </DisabledViewWrapper>
    </ThemeProvider>
  );
  return useCardSectionView(editView, summaryView);
}

export default AudienceTargetingContainer;
